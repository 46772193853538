@mixin break-point {
  position: fixed;
  left: -$sidebar-width;
  &.collapsed {
    left: -$sidebar-collapsed-width;
  }
  &.toggled {
    left: 0;
    .overlay {
      display: block;
    }
  }
  &.rtl {
    left: auto;
    right: -$sidebar-width;
    &.collapsed {
      left: auto;
      right: -$sidebar-collapsed-width;
    }
    &.toggled {
      left: auto;
      right: 0;
    }
  }
}

.pro-sidebar {
  color: $sidebar-color;
  height: 100%;
  width: $sidebar-width;
  min-width: $sidebar-width;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 2009;
  border-right: 1px solid $border-color;
  > .pro-sidebar-inner {
    background: $sidebar-bg-color;
    height: 100%;
    position: relative;
    z-index: 2001;

    // use padding
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);

    > img.sidebar-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      opacity: 0.3;
      left: 0;
      top: 0;
      z-index: 2000;
    }

    > .pro-sidebar-layout {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 2001;

      .pro-sidebar-header {
        border-bottom: 1px solid $border-color;
      }
      .pro-sidebar-content {
        flex-grow: 1;
      }
      .pro-sidebar-footer {
        border-top: 1px solid $border-color;
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#000, 0.3);
    z-index: 2000;
    display: none;
  }

  &.collapsed {
    width: $sidebar-collapsed-width;
    min-width: $sidebar-collapsed-width;
  }

  &.rtl {
    text-align: right;
    direction: rtl;
  }

  // &.xs {
  //   @include media-breakpoint-up(xs) {
  //     @include break-point;
  //   }
  // }
  // &.sm {
  //   @include media-breakpoint-up(sm) {
  //     @include break-point;
  //   }
  // }
  // &.md {
  //   @include media-breakpoint-up(md) {
  //     @include break-point;
  //   }
  // }
  // &.lg {
  //   @include media-breakpoint-up(lg) {
  //     @include break-point;
  //   }
  // }
  // &.xl {
  //   @include media-breakpoint-down(xl) {
  //     @include break-point;
  //   }
  // }
  // &.xxl {
  //   @media (max-width: $breakpoint-xxl) {
  //     @include break-point;
  //   }
  // }
  &.always {
    @include break-point;
  }
}
