%pro-item-prefix {
  &:before {
    content: "";
    display: inline-block;
    width: 4px;
    min-width: 4px;
    height: 4px;
    border: 1px solid $submenu-bg-color;
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
    box-shadow: 1px 0px 0px $sidebar-color, 0px -1px 0px $sidebar-color,
      0px 1px 0px $sidebar-color, -1px 0px 0px $sidebar-color;
  }
}

%text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pro-sidebar {
  .pro-menu {
    padding-top: 10px;
    padding-bottom: 10px;

    > ul {
      > .pro-sub-menu {
        > .pro-inner-list-item {
          position: relative;
          background-color: $submenu-bg-color;
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;

          > div > ul {
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
      }
    }
    a {
      text-decoration: none;
      color: $sidebar-color;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
      }
      &:hover {
        color: $highlight-color;
      }
    }
    .pro-menu-item {
      font-size: $font-size-base;
      // add margin-bottom
      &:not(:last-child) {
        margin-bottom: map-get($spacers, 2);
      }
      // add new hover styles

      &.active {
        color: $highlight-color;
        // add new styles for active item
        font-weight: $font-weight-bold;
        & > .pro-inner-item {
          background-color: var(--bs-gray-400);
          & > .pro-item-content > a {
            color: var(--bs-primary);
          }
        }
      }

      .suffix-wrapper {
        opacity: 1;
        transition: opacity 0.2s;
      }
      .prefix-wrapper {
        display: flex;
        margin-right: 5px;
        opacity: 1;
        transition: opacity 0.2s;
      }

      > .pro-inner-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 8px 35px 8px 20px;
        cursor: pointer;

        // transitions
        transition: all 150ms ease-out;

        // border-radius
        border-radius: $border-radius;

        &:focus {
          outline: none;
          color: var(--bs-light);
        }
        > .pro-icon-wrapper {
          margin-right: 10px;
          font-size: 14px;
          width: $icon-size;
          min-width: $icon-size;
          height: $icon-size;
          line-height: $icon-size;
          text-align: center;
          display: inline-block;

          .pro-icon {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
          }
        }

        > .pro-item-content {
          flex-grow: 1;
          flex-shrink: 1;

          @extend %text-truncate;
        }

        &:hover {
          color: $highlight-color;
          background-color: var(--bs-gray-400);
          // disable animation
          // .pro-icon-wrapper {
          //   .pro-icon {
          //     animation: swing ease-in-out 0.5s 1 alternate;
          //   }
          // }
        }
      }

      &.pro-sub-menu {
        > .pro-inner-item {
          &:before {
            // background: $sidebar-color;
          }

          > .pro-arrow-wrapper {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

            .pro-arrow {
              display: inline-block;
              border-style: solid;
              border-color: $sidebar-color;
              border-width: 0 2px 2px 0;
              padding: 2.5px;
              vertical-align: middle;
              transition: transform 0.3s;
              transform: rotate(-45deg);
            }
          }
        }

        &.open {
          > .pro-inner-item {
            background-color: var(--bs-gray-800);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:before {
              background: transparent !important;
            }
            > .pro-arrow-wrapper {
              .pro-arrow {
                transform: rotate(45deg);
              }
            }
          }
        }

        .pro-inner-list-item {
          padding-left: $submenu-indent;

          .pro-inner-item {
            padding: 8px 30px 8px 15px;

            > .pro-icon-wrapper {
              background: none;
              width: auto;
              min-width: auto;
              height: auto;
              line-height: auto;
            }
          }
        }
      }
    }

    &:not(.inner-submenu-arrows) {
      .pro-inner-list-item
        .pro-menu-item.pro-sub-menu
        .pro-inner-item
        .pro-arrow-wrapper {
        display: none;
      }
    }
    &.submenu-bullets {
      .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
        @extend %pro-item-prefix;
      }
    }

    &.shaped {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            color: var(--bs-dark);
            background-color: $icon-bg-color;
          }
        }
      }
    }

    &.square {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            border-radius: 0;
          }
        }
      }
    }
    &.round {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            border-radius: $border-radius;
          }
        }
      }
    }
    &.circle {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            border-radius: $border-radius-pill;
          }
        }
      }
    }
  }

  // styling when the sidebar is collapsed

  &.collapsed {
    .pro-menu {
      > ul {
        > .pro-menu-item {
          > .pro-inner-item {
            > .suffix-wrapper,
            > .prefix-wrapper {
              opacity: 0;
            }
          }
          > .pro-inner-list-item {
            background-color: $submenu-bg-color-collapsed;
            z-index: 111;
          }
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
          }
          &.pro-sub-menu {
            position: relative;

            > .pro-inner-item {
              pointer-events: none;

              > .pro-arrow-wrapper {
                display: none;
              }
            }
            > .pro-inner-list-item {
              height: auto !important;
              position: fixed;
              visibility: hidden;
              min-width: 220px;
              max-width: 270px;
              background-color: transparent;
              max-height: 100%;
              padding-left: 3px;
              &.has-arrow {
                padding-left: 10px;
              }
              > .popper-inner {
                max-height: 100vh;
                overflow-y: auto;
                background-color: $submenu-bg-color-collapsed;
                padding-left: 20px;
                border-radius: 4px;
              }
            }
            &:hover {
              > .pro-inner-list-item {
                transition: visibility, transform 0.3s;
                visibility: visible;
              }
              // disable animation
              // .pro-icon-wrapper {
              //   .pro-icon {
              //     animation: swing ease-in-out 0.5s 1 alternate;
              //   }
              // }
            }

            .pro-inner-list-item {
              .pro-sub-menu-item,
              .pro-inner-item {
                padding: 8px 30px 8px 5px;
              }
            }
          }
        }
      }
    }
  }

  // styles for rtl
  &.rtl {
    .pro-menu {
      .pro-menu-item {
        .prefix-wrapper {
          margin-right: 0;
          margin-left: 5px;
        }
        > .pro-inner-item {
          padding: 8px 20px 8px 35px;
          > .pro-icon-wrapper {
            margin-right: 0;
            margin-left: 10px;
          }
        }

        &.pro-sub-menu {
          > .pro-inner-item {
            > .pro-arrow-wrapper {
              right: auto;
              left: 20px;
              .pro-arrow {
                transform: rotate(135deg);
              }
            }
          }
          &.open {
            > .pro-inner-item {
              > .pro-arrow-wrapper {
                .pro-arrow {
                  transform: rotate(45deg);
                }
              }
            }
          }

          .pro-inner-list-item {
            padding-left: 0;
            padding-right: $submenu-indent;

            .pro-inner-item {
              padding: 8px 15px 8px 30px;
              &:before {
                margin-right: 0;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }

    // styles for rtl and collapsed
    &.collapsed {
      .pro-menu {
        > ul {
          > .pro-menu-item {
            &.pro-sub-menu {
              > .pro-inner-list-item {
                padding-left: 0;
                padding-right: 3px;
                &.has-arrow {
                  padding-right: 10px;
                }
                > .popper-inner {
                  padding-left: 0;
                  padding-right: 20px;
                }
              }

              .pro-inner-list-item {
                .pro-sub-menu-item,
                .pro-inner-item {
                  padding: 8px 5px 8px 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
