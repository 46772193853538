// @import "../../node_modules/bootstrap/scss/bootstrap";

// Bootstrap base

// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";

// variables
$link-decoration: none;
@import "../../node_modules/bootstrap/scss/variables";

// Configuration
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/tables";
// @import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
// @import "../../node_modules/bootstrap/scss/transitions";
// @import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
// @import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
// @import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// custom scripts
@import "scss/sidebar/sidebar.scss";
@import "scss/indico-auth.scss";
@import "scss/indico-buttons.scss";
@import "scss/indico-container.scss";
@import "scss/indico-event.scss";
@import "scss/indico-header-custom.scss";
@import "scss/indico-qtip.scss";
