.login-page {
  background-color: var(--bs-light);
  margin: 0;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: map-get($spacers, 5);
  padding-bottom: map-get($spacers, 5);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto;
  min-height: 100vh;

  & .middle {
    @extend .container;
    max-width: 800px;
    & .centered-column-wrapper {
      position: relative;
      margin: auto;
      width: 100%;
      background-color: var(--bs-white);
      @extend .shadow-lg;
      border-radius: $border-radius;
      display: flex;
      flex-direction: row;

      & .auth-image-wrapper {
        background-color: var(--bs-light);
        border-radius: $border-radius;
        width: 50%;
        display: flex;
        padding: 2rem;
        min-height: 70vh;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      & .centered-column {
        width: 50%;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0px;
        margin-right: 0px;
        @extend .py-5;
        @extend .px-3;
        display: flex;
        flex-direction: column;

      }
    }
  }
  // form
  form {
    input:not([type="submit"]) {
      // height: 2.5em;
      // padding: 0.5em 1em;
      // line-height: 1.5;
      // width: 100%;
      color: var(--bs-dark);
      border: 1px solid var(--bs-gray-400);
      margin-top: 0px;
      margin-bottom: map-get($spacers, 3);
    }
  }

  .forgot-my-password {
    text-align: left;
    margin-top: 0px;
    margin-bottom: map-get($spacers, 3);
  }
  .login-form-button {
    @extend .btn;
    background-color: var(--bs-secondary);
    color: var(--bs-white);
  }

  .link {
    margin-top: map-get($spacers, 3);
    a.icon-prev {
      @extend .btn;
      border: 1px solid var(--bs-gray-400);
      color: var(--bs-gray-600);
      &::before {
        margin-right: map-get($spacers, 1);
      }
    }
  }
}
