// add styling to main container
.main {
  min-height: 100vh;
}

.category-content-wrapper {
  @extend .container;
}

.category-content {
  @extend .row;
  // overrides
  width: auto;
  max-width: none;
}

.category-info {
  @extend .col;
  @extend .col-12;
  // make paragraph a lead
  & > p {
    @extend .lead;
  }
}

.event-list {
  @extend .col;
  @extend .col-12;

  // override strange margins/padding
  h4 {
    margin-left: 0px;
  }
  li {
    margin-left: 0px;
  }
}

// made for event settings
.layout-side-menu {
  @extend .row;

  & > .menu-column,
  & > .content-column {
    @extend .col;
    @extend .col-12;
  }
}

.banner .title {
  color: var(--bs-primary);
}

.main-breadcrumb .path {
  @extend .container;
}

#profile-picture-selection {
  @include media-breakpoint-down(md) {
    & .ui.centered.four.cards {
      flex-direction: column;
    }
    & .ui.four.cards > .card {
      width: calc(100% - 1.5em);
    }
  }
}

// fix styling on favorites section: user/favorites/
#user-favorites .row {
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  & > div {
    @extend .col;
    &.column {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      @extend .pb-5;
    }
    @include media-breakpoint-down(md) {
      &.col-50 {
        width: 100%;
      }
    }
  }
}

// use if side bar should be always shown
// .header {
//   margin-left: 270px;
// }

// .main {
//   margin-left: 270px;
// }

// .footer {
//   margin-left: 270px;
// }

// /register
.fixed-width-standalone-page {
  @extend .container;
}

// /register
.i-form.horizontal {
  .form-group {
    display: flex !important;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      & .form-label {
        width: auto;
        text-align: left;
        & .required {
          right: auto;
        }
        &:not(:empty) {
          padding-top: 1rem;
          & .required {
            top: 1rem;
          }
        }
        &:empty {
          display: none;
          height: 0px;
          overflow: hidden;
        }
      }
      & .form-field {
        width: 100%;
        text-align: left;
        padding-left: 0px;
      }
    }
  }
}

// /admin/theme/
.theme-site-header-image-form, .theme-site-login-image-form, .favicon-form {
  .form-field {
    padding-left: 0px !important;
    width: 100% !important;
  }
}
