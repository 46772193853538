.confheader {
  background-color: var(--bs-light);
  border-bottom: 1px solid var(--bs-gray-400);
}

.confTitleBox {
  @extend .container;

  // overrides
  background: transparent;
  border-bottom: none;
  border-top: none;
  // padding: 0;
  min-height: auto;
}

.confTitle {
  @extend .row;
  & > h1 {
    @extend .col;
  }

  // overrides
  width: auto;
}

.conference-title-link {
  color: var(--bs-primary);
  font-weight: $font-weight-bold;
  padding: 0px;
  @extend .py-3;
}

.confSubTitleBox {
  @extend .container;

  // overrides
  background: transparent;
  border-bottom: none;
  border-top: none;
  // padding: 0;
}

.confSubTitleContent.flexrow {
  @extend .row;

  // overrides
  width: auto;

  & > .search-box {
    @extend .col;
    @extend .col-12;
    @extend .col-md-4;
  }
}

.confSubTitle.f-self-stretch {
  @extend .col;
  @extend .col-12;
  @extend .col-md-8;
}

#confSectionsBox {
  @extend .container;
}

// #flashed-messages {
//   @extend .row;
// }

.conf_leftMenu {
  @extend .row;

  // overrides
  width: auto;
  float: none;

  & > ul {
    @extend .col;
  }

  & #outer {
    background: transparent;
    border: none;
  }
}

.confBodyBox {
  @extend .row;
}

.conference-page header h2 {
  color: var(--bs-primary);

  // overrides
  padding-right: 0px;
}

.management-page div.page-content {
  max-width: none !important;
}

// #session-bar {
//   @include media-breakpoint-down(xl) {
//     & #tz-selector-link {
//       & {
//         visibility: hidden;
//       }
//       &::before,
//       &::after {
//         visibility: visible;
//       }
//     }
//   }
// }

.conference-page .infogrid {
  @extend .row;
  margin-left: 0px;
  margin-right: 0px;

  & .infoline {
    @extend .col;
    @extend .col-12;
    &.date,
    &.location,
    &.chairs,
    &.material {
      @extend .col-md-6;
      @extend .mb-3;
    }
  }
}
