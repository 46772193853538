.qtip-shadow,
.qtip-default.qbubble {
  -webkit-box-shadow: $box-shadow-lg;
  -moz-box-shadow: $box-shadow-lg;
  box-shadow: $box-shadow-lg;
}

.i-dropdown {
  box-shadow: $box-shadow-lg;
}
