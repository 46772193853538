.custom-header {
  #header-background-image {
    background-size: 100%;
    @media (min-width: 1200px) {
      background-size: 80%;
    }
  }
  .custom-header-overlay {
    overflow-y: hidden;
    width: 18.75%;
    @media (min-width: 1200px) {
      width: 15%;
    }
  }
  .custom-header-date {
    width: 100%;
    @media (min-width: 1200px) {
      width: 40%;
      clip-path: url(#clip-path-date);
    }
  }
}
